import React, {Component} from "react";
import ISRCfinder3 from './attempt 3'
import './App.css'
import FadeIn from "react-fade-in"
import Spinner from 'react-bootstrap/Spinner'
import { CSVLink } from "react-csv";

class Table extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            trackarray: [],
            tracklist:[],
            complete: false
        }
    }

    async componentDidMount() {
        this.setState({isLoading:true})
        console.log(this.props.spotifyurl)
        console.log('that was spotify url')
        this.getISRCS()
    }

    componentWillUnmount() {
    }

    returntrackdata () {
            this.forceUpdate()
            console.log(this.state.tracklist)
            console.log(this.state.trackdata)
            console.log(this.state.complete)
            return this.state.tracklist

    }

    async getISRCS() {
        let trackdata = new ISRCfinder3();
        let p = await trackdata.ISRCsearch3(this.props.spotifyurl)
        this.setState({tracklist:p})
        this.setState({isLoading: false})
        this.setState({complete:true})
    }

    renderTableData() {

        if (this.state.complete === true) {

            return this.state.tracklist.map((list, index) => {
                const {TrackNumber, SongName, ISRC, duration} = list
                return (
                    <div>
                        <FadeIn>
                            <div>
                            <table id='students' >
                                <tr key={{TrackNumber}}>
                                    <td>{TrackNumber}</td>
                                    <td>{SongName}</td>
                                    <td>{ISRC}</td>
                                    <td>{duration}</td>
                                </tr>
                            </table>
                            </div>
                        </FadeIn>
                    </div>

                )
            })
        }

        else if (this.state.isLoading === true) {
            return (
                <div id='spinnerdiv'>
                    <Spinner animation="border" variant='primary' size={'l'} id='spinner'/>
                </div>

            )
        }

        else if (this.state.isLoading === false) {
            return null
        }
    }

    renderTableHeader () {
        if (this.state.complete === true) {
            let albumTitle = this.state.tracklist[0].album
            console.log(this.state.tracklist[0].releasedate)

            let releasedate = (this.state.tracklist[0].releasedate).replaceAll('-','/')

            console.log(albumTitle)
            return (
            <div className={'albumtitlediv'}>
                <FadeIn>
                <h1 className={'albumtitle'}>{albumTitle}</h1>
                <h1 className={'albumtitle'}>{'Released: ' + releasedate}</h1><p className={'ymd'}>Date format is (YYYY/MM/DD)</p>
                </FadeIn>
            </div>
            )
        }

        else if (this.state.complete === false) {
            return null
        }

    }

    render () {
        return (
            <div id='centre'>
                {this.renderTableHeader()}
                {this.renderTableData()}
                <FadeIn delay={2000}>
                <div className='downloadcsvbox'>
                    <CSVLink data={this.state.tracklist} filename={'ISRCCODES.csv'} className='downloadcsv'>Download Here (.CSV)</CSVLink>;
                </div>
                </FadeIn>

            </div>

        )
    }
}

export default Table;
