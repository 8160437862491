import React from 'react';
import './App.css';
import './App2.css'
import './App2content.css'
import './Button Styles.css'
import logo from './Images/FIND MY ISRCS LOGO updated darker.jpg-1.png';
import poweredby from './Images/POWERED BY GYROSTREAM.png'
import howtotransfer from './Images/APP2/How to Transfer to simple.png'
import neonfloor from './Images/thin floor fade.png'
import Table from './Table.js'
import "bootstrap/dist/css/bootstrap.min.css"
import Fade from 'react-bootstrap/Fade'
import lightbulbs from './Images/APP2/lightbulbs.png'
import af from './Images/APP2/as2.png'
import onemorerelease from './Images/APP2/onemorerelease.png'
import gs from './Images/gs.png'
import ohYeah from './Images/APP2/howgeturi.png'
import ReactImageTooltip from 'react-image-tooltip'
import thoughtbubble from './Images/APP2/thought bubble.png'
import amazingsupport from './Images/APP2/Get ready for amazing artist support 2.png'
import FadeIn from "react-fade-in";
import scrolldown from './Images/APP2/Scroll down.png'
import Zoom from 'react-reveal/Zoom';
import Fadein from 'react-reveal/Fade'


class Main extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            urilisty: false,
            value: '',
            inputvisible: true,
            open: true,
            error: false,
            trackdata: "hello"
        }
        this.trigger = this.trigger.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.originalState = this.originalState.bind(this)
        this.errorbox = this.errorbox.bind(this)
    }

    trigger() {
        console.log(this.state.value)
        // this.setState({error: false})
        let stringlength = this.state.value.length

        if (stringlength === 36 || stringlength === 79 || stringlength === 73 || stringlength === 91) {
            this.setState({urilisty: true})
            this.setState({open: false})
            setTimeout(this.setState({inputvisible: false}),1500)
        }
        else if (stringlength !== 36 || stringlength !== 79 || stringlength !==73 || stringlength !== 91 || stringlength === 0) {
            // console.log('error')
            this.setState({error:true})
            console.log(this.state.error)
            this.forceUpdate()
        }

    }

    handleChange(event) {
        this.setState({value: event.target.value});
        console.log(this.state.value)

    } originalState () {
        this.setState({urilisty:false, value:'', inputvisible: true, open:true, error: false})
}

    /// IS ENTERED STRING A VALID SPOTIFY URI OR URL
    errorbox () {
        // let stringlength = this.state.value.length
        if (this.state.error === false) {
            console.log('no error')
            return null
        }
        else if (this.state.error === true) {
            console.log('error')
            return (
                <div>
                    <h3 className={'errorbox'}> PLEASE ENTER A VALID SPOTIFY TRACK OR ALBUM URI/URL </h3>

                </div>

            )
        }
    }
/// RE_SEARCH BUTTON
    Tablepop () {
        if (this.state.urilisty ===false) {
            return null
        }
        else if (this.state.urilisty === true) {
            return (
                <div className={'tablediv'}>
                    <Table tracklist={this.state.trackdata} spotifyurl={this.state.value}/>
                    <div className='container2'>

                        <label className='buttonlabel'>
                                <FadeIn delay={4000}>
                                <button className='re-search-Button' onClick={this.originalState}>Search again</button>
                                </FadeIn>
                        </label>
                    </div>
                </div>
            )
        }
    }
    /// REGULAR SEARCH BUTTON AND INPUT
    inputboxelements () {
        return (
            <Fade className='fade' in={this.state.open}>
            <div className='input'>
                {this.errorbox()}
                <label className='labels'>
                    <input className="App-input" type='text' onChange={this.handleChange}
                           placeholder={"ENTER SPOTIFY URi OR URL"} onfocus={"this.placeholder = ''"}/>

                </label>
                <button className='search-Button' onClick={this.trigger}> GET ISRC </button>
                <ReactImageTooltip image={ohYeah} >
                    <a href='https://community.spotify.com/t5/Spotify-Answers/What-s-a-Spotify-URI/ta-p/919201' target={"_blank"} rel="noopener noreferrer"
                       id='tooltip'>
                        ⓘ
                    </a>
                </ReactImageTooltip>
            </div>
            </Fade>
        )
    }

    inputbox() {
        if (this.state.inputvisible === true) {
            return (
                this.inputboxelements()
            )

        } else {
            return (
                <Fade in={this.state.open}>
                    {this.inputboxelements()}
                </Fade>
            )
        }
    }

    render (){
        return (
        <div>
            <div className='nosidescroll'>

            <div className='App'>
                {this.inputbox()}
                {this.Tablepop()}
                   <p className={'filler'}>.</p>
                    <nav className='nav-bar'>

                        <a href='https://www.gyrostream.com' target={'_blank'} rel="noopener noreferrer"><img src={gs} alt={'gs'} className='gsnav'/></a>
                        <a className="active" href="https://www.gyrostream.com/sign_in.php" target={"_blank"} rel="noopener noreferrer">Sign In</a>
                        <a href="https://www.gyrostream.com/sign_up.php" target={"_blank"} rel="noopener noreferrer">Sign Up</a>
                    </nav>
                <div className='imagediv'>
                <Zoom>
                <img src={logo} alt={"My Logo"} className="App-Image"/>
                <a href='https://www.gyrostream.com' target={'_blank'} rel="noopener noreferrer"><img src={poweredby} alt={'powered by'} className='poweredby'/></a>
                </Zoom>
                </div>

                <div className={'App-footer'}>
                <img src={neonfloor} className={'neonfloor'} alt={'neonfloor'}/>
                </div>
                <img src={scrolldown} className={'scrolldown'} alt={'scrolldown'}/>


            </div>


            {/*APP 2 BEGINS*/}
            <div className='App2'>

                <img src={lightbulbs} className='lightbulbs'/>
                    <Zoom>
                    <div className='howtotransferflex'>
                        {/*<img src={howtotransfer} className='howtotransfer'/>*/}
                        <h1 className='howtotransfertext'>How to Transfer Between Distributors</h1>
                        <h3 className={'andkeep'}> -And keep your stream count and playlists-</h3>
                    </div>

                    </Zoom>

                {/*CONTENT STARTS HERE*/}

                <div className='centerall'>
                    <Fadein left>
                    <div className='whatyouneed'>
                        <h1 id='whatyouneedh1'>What You Need</h1>
                            <div className='atb'>
                                <img src={af} className='af' alt={'find'}/>
                                <h2 id='whatyouneedh2'>Assets</h2>

                            </div>
                        <p id='whatyouneedp1'>Audio & Image files</p>
                        <p id='whatyouneedp2'>Need help with specs? <a href='https://www.gyrostream.com/blog_details.php?id=25' target ='_blank' rel="noopener noreferrer">Click here</a></p>

                    </div>
                    </Fadein>
                    <Fadein right>
                    <div className='whattodo'>
                        <div className='whattodoml'>
                        <h1 id='whattodoh1'>What To Do</h1>
                        <div>
                        <img src={thoughtbubble} className='thoughtbubble' alt={'thoughtbubble'} />
                        </div>
                            <p>1. Sign up with your new distributor (<a href='https://www.gyrostream.com/sign_up.php' id='whattodoh2' target='_blank' rel="noopener noreferrer">here </a> is one we recommend)</p>
                        <p >2. Upload and submit your release to your new distributor (don't remove your existing release yet!). Your new distributor should allow you to manually input the same ISRC codes for each song from your original distributor.
                            Find your ISRCs using our Spotify search tool above. Ensure that all other details of the song remain the same.</p>
                        </div>
                    </div>
                    </Fadein>
                </div>

                <div className={'switchingsection'}>
                    <Fadein>
                        <div className='switchingover'>
                            <h1 id='switchheader'>Switching Over</h1>
                                <p> When the new version goes live on Spotify, it will merge with the existing release after 48hrs.
                                    This will copy over all the stream counts and playlists to the new version of the tracks.
                                    Click into the album page on Spotify desktop app and check this icon exists at the bottom right of your release;
                                    </p>
                            <div className='onemorereleaseimg'>
                                <img src={onemorerelease} alt={'one more release'} classname="onemorerelease"/>
                            </div>
                                <p>This indicates that 2 versions of the same release exists in Spotify systems, and they have successfully merged. When you see this button, send a request to your previous distributor to remove the old version from all online platforms platforms;</p>
                        </div>
                    </Fadein>
                    <Fadein>
                        <div className="stores">
                            <div className='storecenter'>
                            <div id='half1'>
                            <h3 id='storeheader'> DITTO </h3> <p> go to your release and click the 'Takedown' button.</p>
                            <h3 id='storeheader'>Distrokid</h3> <p>Click 'Remove my music from all stores' button when viewing the product.</p>
                            </div>
                            <div id='half2'>
                                <div className='tc'>
                            <h3 id='storeheader'>Tunecore</h3>
                                <p><a href="https://support.tunecore.com/hc/en-au/articles/115006684508-How-do-I-take-down-my-music-from-stores-"
                                                                     target={"_blank"} rel="noopener noreferrer">Click Here</a></p>
                                </div>
                                <div className='cd'>
                            <h3 id='storeheader'>CD Baby</h3>
                                <p><a href="https://support.duplication.cdbaby.com/hc/en-us/articles/213598977-How-can-I-cancel-the-distribution-of-an-album-I-am-currently-distributing-through-CD-Baby-"
                                                                    target={"_blank"} rel="noopener noreferrer">Click Here</a></p>
                                </div>
                            </div>
                            </div>
                        </div>
                    </Fadein>
                    <Fadein>
                        <div className='recommenddiv'>
                            <h4 className='recommend'> We recommend you don't close your account as there may be royalties owing to you in future months</h4>
                        </div>
                    </Fadein>
                    </div>
                    <Fadein>
                        <div className='nowswitch'>
                            <img src={amazingsupport} alt={'one more release'} className="see"/>
                            <div className='see2'>
                            <a className='see2' target= '_blank' rel="noopener noreferrer" href='https://www.google.com/search?q=google+reviews+gyrostream&oq=google+reviews+gyrostream&aqs=chrome..69i57j0i433i457j0l5.4814j1j1&sourceid=chrome&ie=UTF-8#lrd=0x6b9159f326ac0001:0xec8cb841c38f0f32,1,,,'>
                                → See for yourself ← </a>
                            </div>

                        </div>
                    </Fadein>

            </div>
            </div>
        </div>
        )}
}

export default Main;
