import React from "react";

class ISRCfinder3 extends React.Component {
    constructor() {
        super();
        this.state = {
        };
    }

    async ISRCsearch3(spotifyurl) {

        let trackoralbumresult = this.trackoralbum(spotifyurl)
        let apiurl = trackoralbumresult[0]
        console.log(apiurl)
        console.log('that was apiurl')
        let istrack = trackoralbumresult[1]
        console.log(istrack)
        const request = require('request-promise-native'); // "Request" library
        const client_id = '7620bf9bd9664113a4484155ba091a54'; // Your client id
        const client_secret = 'c8181314aec94d418a7c7a1fe70c7f75'; // Your secret

        // your application requests authorization
        let authOptions = {
            url: 'https://accounts.spotify.com/api/token',
            headers: {
                'Authorization': 'Basic ' + (new Buffer(client_id + ':' + client_secret).toString('base64')),
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            form: {
                grant_type: 'client_credentials'
            },
            json: true,
            async: true
        };

        const result = await (request.post(authOptions));
        const token = await result.access_token
        console.log(token)
        let options = {
            url: apiurl,
            headers: {
                'Authorization': 'Bearer ' + token
            },
            json: true,
            async: true
        };

        const trackdata = await (request.get(options));
        console.log(trackdata)
        console.log('that was trackdata')
        let uris = this.iftrack(trackdata,istrack,token)

        return uris
    }

    async iftrack (trackdata,trackTorF, token) {
        let uris = []
        if (trackTorF) { //processes the result if the track is an album
            try {
                uris.push(
                    {
                        TrackNumber: trackdata.track_number,
                        SongName: trackdata.name,
                        ISRC: trackdata.external_ids.isrc,
                        album: trackdata.album.name,
                        releasedate: trackdata.album.release_date,
                        duration: this.timeformat(trackdata.duration_ms)
                    })
                console.log(uris)
                console.log('The track list is above');

            } catch (e) {
                console.log(e)
            }

        } else if (trackTorF  === false) { //THIS IS WHERE YOU IMPLEMENT GET SEVERAL TRACKS
            console.log('here')
            let request = require('request-promise-native')
            const items = trackdata.items
            console.log(trackdata.items);
            let apiurl = 'https://api.spotify.com/v1/tracks/?ids='
            try {
                for (let p in items) {
                    apiurl += items[p].uri.substr(14, 22) + ','

                }
                apiurl = apiurl.slice(0,-1)
                console.log(apiurl)
                let options = {
                    url: apiurl,
                    headers: {
                        'Authorization': 'Bearer ' + token
                    },

                    json: true,
                    async: true
                };
                const trackdata = await (request.get(options));
                console.log(trackdata)
                let itemes = trackdata.tracks
                for (let p in items) {
                    uris.push(
                        {
                            TrackNumber: itemes[p].track_number,
                            SongName: itemes[p].name,
                            ISRC: itemes[p].external_ids.isrc,
                            album: itemes[p].album.name,
                            releasedate: itemes[p].album.release_date,
                            duration: this.timeformat(itemes[p].duration_ms)

                        })
                }
                console.log(uris)
            }
             catch (e) {
                console.log(e)
            }
        }
        console.log(uris)
        return uris
    }
    //THIS IS MISSING ANYTHIGN WITH a time that has no minutes parameter
    timeformat(duration) {
        console.log(duration)
        let testduration = this.millisToMinutesAndSeconds(duration)
        let newduration = String((duration/1000)/60)
        let secondsmultiplier = Number(newduration.substring(newduration.indexOf(".") + 1))
        let seconds = String(Math.round(Number('.' + String(secondsmultiplier))*60))
        if (seconds.length === 1) {
            seconds = '0' + seconds
        }
        console.log(seconds)
        console.log('that was seconds')
        let minutes = newduration.substring(0, newduration.indexOf("."))
        newduration = minutes + ':' + seconds

        return testduration
    }

    millisToMinutesAndSeconds(millis) {
        var minutes = Math.floor(millis / 60000); // correct
        var seconds = ((millis % 60000) / 1000).toFixed(0);
        return (
            seconds == 60 ?
                (minutes+1) + ":00" :
                minutes + ":" + (seconds < 10 ? "0" : "") + seconds
        );
    }

    trackoralbum(spotifyurl) {

        if (spotifyurl.includes(" ")) {
            spotifyurl = spotifyurl.replace(" ",'')
        }
        console.log(spotifyurl)
        let istrack = false
        let apilink = 'https://api.spotify.com/v1/'

        if (spotifyurl.includes('.com/album')) {
            console.log('full album url');
            let ui = spotifyurl.substr(31, 22)
            apilink += 'albums/' + ui + '/tracks' + '?limit=50'
            console.log(apilink + '   :<-- That was API LINK')
            console.log(ui)

        } else if (spotifyurl.includes('album:')) {
            console.log('album uri inputted');
            let ui = spotifyurl.substr(14, 22)
            apilink += 'albums/' + ui + '/tracks'  + '?limit=50'
            console.log(apilink + '   :<-- That was API LINK')
            console.log(ui)

        } else if (spotifyurl.includes('.com/track')) { //RETURNS ISRC IMMEDIATELY
            console.log('full track url inputted');
            let ui = spotifyurl.substr(31, 22)
            apilink += 'tracks/' + ui
            console.log(ui)
            istrack = true

        } else if (spotifyurl.includes('track:')) { //RETURNS ISRC IMMEDIATELY
            console.log('track URI inputted');
            let ui = spotifyurl.substr(14, 22)
            apilink += 'tracks/' + ui
            console.log(ui)
            istrack = true
        }
        console.log(apilink)
        return [apilink,istrack]
        // return api link based on whether it is an album or a track (/tracks at the end or not)
    }


}
export default ISRCfinder3;

